import request from "@/Axios/request";
const baseUrl = `${process.env.VUE_APP_CRONE_SERVICE}/api/v1/ekyc`;
export default {
    getscanSanction() {
        return request({
            url: `${baseUrl}/scan-sanction`,
            method: "get",
        });
    },

}