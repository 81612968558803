<template>
  <section class="pa-2">
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.9"
      color="#ff6b00"
      :height="height"
      :width="width"
    />

    <v-form ref="passform">
      <kycHeadingVue :message="success_msg" :kycStatus="kycStatus" />
      <div v-if="kycStatus === 'Approved'">
        <kyc-success />
      </div>
      <v-row v-if="kycStatus === 'Rejected'">
        <KycFail @kycupdate="getkycstatus()"
      /></v-row>
      <v-row>
        <v-card flat :disabled="kycStatus != 'Draft'">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <label
                  >{{ $t("idType") }}<span style="color: #f00">*</span></label
                >
                <v-select
                  v-model="id_type"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="validations.id_proofrules"
                  :items="idtypes"
                  outlined
                  required
                  :placeholder="$t('idtype_select_placeholder')"
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <label
                  >{{ $t("idNumber") }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  v-model="user_details.idNumber"
                  :rules="validations.id_numberRules"
                  :placeholder="$t('id_number_placeholder')"
                  outlined
                  required
                  dense
                  :class="{ 'is-invalid': $v.user_details.idNumber.$error }"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label
                  >{{ $t("isuueDate")
                  }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  v-model="user_details.issue_date"
                  :rules="validations.issuedaterules"
                  dense
                  required
                  outlined
                  type="date"
                  :max="currentDate"
                />
              </v-col>

              <v-col cols="12" md="6">
                <label
                  >{{ $t("Location")
                  }}<span v-if="id_type === 'Passport'" style="color: #f00"
                    >*</span
                  ></label
                >
                <v-autocomplete
                  v-model="user_details.issue_location"
                  :items="country_options"
                  :rules="validations.issue_locationRules"
                  :disabled="
                    disableValues || disableBystatus || disableIssueLoc
                  "
                  :placeholder="$t('Location')"
                  dense
                  outlined
                  required
                  :min="currentDate"
                />
              </v-col>

              <v-col cols="12" md="6">
                <label
                  >{{ $t("expiryDate")
                  }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  v-model="user_details.expiry_date"
                  :rules="validations.expDateRules"
                  dense
                  outlined
                  required
                  type="date"
                  :min="currentDate"
                />
              </v-col>

              <v-col cols="12" md="6">
                <label
                  >{{ $t("IDFirstPage")
                  }}<span style="color: #f00">*</span></label
                >
                <v-file-input
                  v-model="user_details.first_page"
                  :rules="validations.firstpage_rules"
                  dense
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('idtype_first_page_placeholder')"
                  outlined
                  ref="idfirstfile"
                  required
                  prepend-icon=""
                  :clearable="false"
                  @change="fileUpload($event, 'FRONT_PAGE')"
                >
                  <template v-slot:append>
                    <v-icon
                      aria-hidden="false"
                      class="color-primary"
                      @click="openIdfirst"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>

                  <template v-slot:append-outer>
                    <v-img
                      v-if="frontPageUrl"
                      class="ml-1"
                      max-height="40"
                      max-width="50"
                      :src="frontPageUrl"
                      @click="handleImageViewer(frontPageUrl)"
                    />
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6">
                <label>{{ $t("IDLastPage") }}</label>
                <v-file-input
                  v-model="user_details.last_page"
                  dense
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('idtype_last_page_placeholder')"
                  outlined
                  ref="idlastfile"
                  required
                  prepend-icon=""
                  @change="fileUpload($event, 'BACK_PAGE')"
                >
                  <template v-slot:append>
                    <v-icon
                      aria-hidden="false"
                      class="color-primary"
                      @click="openIdlast"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>

                  <template v-slot:append-outer>
                    <v-img
                      v-if="backPageUrl"
                      class="ml-1"
                      max-height="40"
                      max-width="50"
                      :src="backPageUrl"
                      @click="handleImageViewer(backPageUrl)"
                    />
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6"  v-if="user_type != 'BUS'">
                <label>
                  {{ $t("Selfy") }}<span style="color: #f00">*</span></label
                >
                <v-file-input
                  v-model="user_details.selfieFile"
                  :rules="validations.selfy_rules"
                  dense
                  ref="selfiefile"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('idtype_selfy_page_placeholder')"
                  outlined
                  prepend-icon=""
                  @change="fileUpload($event, 'FACE_IMAGE')"
                >
                  <template v-slot:append>
                    <v-icon
                      aria-hidden="false"
                      class="color-primary"
                      @click="open"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </template>

                  <template v-slot:append-outer>
                    <v-img
                      v-if="selfyPageUrl"
                      class="ml-1"
                      max-height="40"
                      max-width="50"
                      :src="selfyPageUrl"
                      @click="handleImageViewer(selfyPageUrl)"
                    />
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card
          flat
          width="100%"
          :disabled="kycStatus != 'Draft'"
          v-if="user_type != 'BUS'"
        >
          <v-card-title class="lighten-2">Customer Appointment: </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <label>
                  {{ $t("Date") }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  v-model="user_details.interviewDate"
                  :rules="validations.interviewData"
                  :input-class="'datepicker-custom'"
                  outlined
                  dense
                  type="date"
                />
              </v-col>
              <v-col cols="12" md="6">
                <label
                  >{{ $t("Time") }}<span style="color: #f00">*</span></label
                >
                <v-autocomplete
                  v-model="user_details.interviewTime"
                  :rules="validations.interviewTime"
                  :items="interview_time_array"
                  :placeholder="$t('select_time')"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col clos="12" md="6">
                <label>{{ $t("City") }}</label>
                <v-autocomplete
                  v-model="user_details.interviewCity"
                  :items="interview_city_array"
                  :placeholder="$t('select_city')"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card> -->
      </v-row>
    </v-form>

    <v-row class="mt-2" v-if="kycStatus === 'Draft'">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            class="float-left mt-5 px-5 ms-3 btn-primary-outlined text-capitalize"
            outlined
            @click="onClickBack"
            ><v-icon dark left>mdi-arrow-left</v-icon>
            {{ $t("backLabel") }}</v-btn
          >
          <v-btn
            class="float-right mt-5 mb-4 me-4 px-5 btn-primary-outlined text-capitalize"
            outlined
            @click="onClickNext(true)"
          >
            <div class="button-loader" v-if="loader">
              <span class="spinner-border spinner-border-sm"></span>
            </div>
            <div>
              {{ $t("submit") }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '350',
        PicWidth: 600,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </section>
</template>
<script>
import imageViewer from "../../tools/imageViewer.vue";
import { mapGetters } from "vuex";
import {
  required,
  requiredIf,
  alphaNum,
  numeric,
} from "vuelidate/lib/validators";
import { i18n as $ } from "../../../locale/index";
import Loading from "vue-loading-overlay";
import { issueDate, expireDate } from "../../../utils/validate";
import KycSuccess from "../kycSuccess.vue";
import KycFail from "../kycFail.vue";
import handleImageUpload from "../../../mixins/compressImage.mixin";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
import commonService from "@/services/CommonService/commonService";
import kycHeadingVue from "./kycHeading.vue";
export default {
  name: "PassportSection",
  components: {
    imageViewer,
    Loading,
    KycSuccess,
    kycHeadingVue,
    KycFail,
  },
  mixins: [handleImageUpload, alerts],
  data() {
    return {
      user_type: sessionStorage.getItem("user_type"),
      id_type: "",
      eKYCStatusMsg: "Pending Verification",
      id_type_code: "",
      idtypes: [],
      idtype_objs: [],
      idtypes_val: [],
      previewImage: "",
      id_type_back: null,
      isfacetoface: false,
      idpreviewImage: "",
      success_msg: "",
      facetoface: true,
      interviewData: {},
      getpagecode: "",
      interview_city_array: [
        { text: "Sydney", value: "Sydney" },
        { text: "Melbourne", value: "Melbourne" },
        { text: "Brisbane", value: "Brisbane" },
        { text: "Perth", value: "Perth" },
        { text: "Adelaide", value: "Adelaide" },
        { text: "Canberra", value: "Canberra" },
        { text: "Hobart", value: "Hobart" },
        { text: "Darwin", value: "Darwin" },
        { text: "Gold Coast", value: "Gold Coast" },
        { text: "Newcastle", value: "Newcastle" },
      ],
      interview_time_array: [
        {
          text: "9Am -10Am",
          value: "09:00:00",
        },
        {
          text: "10Am to 11Am",
          value: "10:00:00",
        },
        {
          text: "11Am to 12Pm",
          value: "11:00:00",
        },
        {
          text: "12Pm to 1Pm",
          value: "12:00:00",
        },
        {
          text: "1Pm to 2Pm",
          value: "13:00:00",
        },
        {
          text: "2Pm to 3Pm",
          value: "14:00:00",
        },
        {
          text: "3Pm to 4Pm",
          value: "15:00:00",
        },
        {
          text: "4Pm to 5Pm",
          value: "16:00:00",
        },
        {
          text: "5Pm to 6Pm",
          value: "17:00:00",
        },
      ],
      selfiUploadImages: "",
      IDfileName: "",
      SelfiefileName: "",
      modelImage: "",
      fullPage: true,
      height: 100,
      width: 100,
      frontPageUrl: null,
      backPageUrl: null,
      selfyPageUrl: null,
      isImageViewer: false,
      imageViewerUrl: "",
      country_options: [],
      country_objs: [],
      issue_location: null,
      issue_location_code: "",
      kycStatus: null,
      disableBystatus: null,
      currentDate: new Date().toJSON().split("T")[0],
      loader: false,
      isdownloadapp: false,
      disableIssueLoc: false,
      issueError: false,
      validations: {
        selfy_rules: [(v) => !!v || $.t("file_select_placeholder")],
        firstpage_rules: [(v) => !!v || $.t("file_select_placeholder")],
        issuedaterules: [(v) => !!v || $.t("issueDateMsg1")],
        issue_locationRules: [],
        expDateRules: [(v) => !!v || $.t("expiryDateMsg1")],
        id_proofrules: [(v) => !!v || $.t("idtypeMsg1")],
        id_numberRules: [(v) => !!v || $.t("idnumberMsg1")],
        interviewData: [(v) => !!v || $.t("interviewDate")],
        interviewTime: [(v) => !!v || $.t("interviewTime")],
      },
    };
  },
  props: {
    user_details: {
      type: Object,
      default() {
        return {};
      },
    },
    disableVals: {
      type: Boolean,
      default: false,
    },
    isloader: {
      type: Boolean,
      default() {
        return false;
      },
    },
    btnDisable: {
      type: Boolean,
      default: false,
    },
    kycdetails: {
      type: Object,
    },
  },
  watch: {
    btnDisable(val) {
      if (val) this.getkycstatus();
    },
    id_type(val) {
      this.idtype_objs.map((i) => {
        if (val === i.value) {
          console.log("i", i);
          this.id_type_code = i.value_code;
        }
      });

      if (val === "Passport") {
        this.validations.issue_locationRules = [
          (v) => !!v || $.t("issue_location_placeholder"),
        ];
      } else {
        this.validations.issue_locationRules = [];
      }
    },
    "user_details.issue_location"(val) {
      this.country_objs.map((i) => {
        if (val === i.iso_alpha3) {
          this.user_details.issue_location = i.country_name;
        }
        if (val === i.country_name || val === i.iso_alpha3) {
          this.issue_location_code = i.iso_alpha3;
        }
      });
    },
    "user_details.issue_date"(val) {
      if (!val < new Date().toJSON().split("T")[0]) {
        this.validations.issuedaterules.push($.t("issue_date_valid"));
      } else {
        this.validations.issuedaterules = [(v) => !!v || $.t("issueDateMsg1")];
      }
    },
    "user_details.expiry_date"(val) {
      if (!val > new Date().toJSON().split("T")[0]) {
        this.validations.expDateRules.push($.t("expiry_date_issue_validate"));
      } else {
        this.validations.expDateRules = [(v) => !!v || $.t("expiryDateMsg1")];
      }
    },
  },
  computed: {
    buttonDisable: {
      get: function () {
        return this.btnDisable;
      },
      set: function (newVal) {
        this.btnDisable = newVal;
      },
    },
    disableValues: {
      get: function () {
        return this.disableVals;
      },
      set: function (newVal) {
        this.disableVals = newVal;
      },
    },

    ...mapGetters("Transactional", ["nationality_list"]),
    ...mapGetters("Authentication", ["country_list"]),
    idproofErrors() {
      const errors = [];
      if (!this.$v.id_type.$dirty) return errors;
      !this.$v.id_type.required &&
        errors.push(this.$t("idtype_select_placeholder"));
      return errors;
    },
    idNumberErrors() {
      const errors = [];
      if (!this.$v.user_details.idNumber.$dirty) return errors;
      if (!this.$v.user_details.idNumber.required) {
        errors.push(this.$t("id_number_placeholder"));
      }
      switch (this.id_type) {
        case "Passport":
        case "Drivers License":
          if (!this.$v.user_details.idNumber.alphaNum) {
            errors.push(this.$t("invalid_id_num"));
          }
          break;
        case "Identification Card":
          if (
            this.user_details.idNumber.length < 12 ||
            this.user_details.idNumber.length > 12
          )
            errors.push(this.$t("id_number_min_number"));
          if (!this.$v.user_details.idNumber.numeric)
            errors.push(this.$t("invalid_id_num"));
          break;
        case "Company Registration":
        case "MyPR":
        case "Other":
        case "Work Permit":
          if (!this.$v.user_details.idNumber.numeric)
            errors.push(this.$t("invalid_id_num"));
          break;
        default:
          break;
      }
      return errors;
    },
    issueDateErrors() {
      const errors = [];
      if (!this.$v.user_details.issue_date.$dirty) return errors;
      !this.$v.user_details.issue_date.required &&
        errors.push(this.$t("issue_date_placeholder"));
      !this.$v.user_details.issue_date.issueDate &&
        errors.push(this.$t("issue_date_valid"));
      // if(!(value >= this.currentDate))
      return errors;
    },
    locationErrors() {
      const errors = [];
      if (!this.$v.user_details.issue_location.$dirty) return errors;
      !this.$v.user_details.issue_location.required &&
        errors.push(this.$t("issue_location_placeholder"));
      return errors;
    },
    expireDateErrors() {
      const errors = [];
      if (!this.$v.user_details.expiry_date.$dirty) return errors;
      !this.$v.user_details.expiry_date.required &&
        errors.push(this.$t("expiry_date_placeholder"));
      !this.$v.user_details.expiry_date.expireDate &&
        errors.push(this.$t("expiry_date_issue_validate"));
      return errors;
    },
    firstPageErrors() {
      const errors = [];
      if (!this.$v.user_details.first_page.$dirty) return errors;
      !this.$v.user_details.first_page.required &&
        errors.push(this.$t("file_select_placeholder"));
      return errors;
    },
    interviewDateErrors() {
      const errors = [];
      if (!this.$v.user_details.interviewDate.$dirty) return errors;
      !this.$v.user_details.interviewDate.required &&
        errors.push("Please select date.");
      return errors;
    },
    interviewTimeErrors() {
      const errors = [];
      if (!this.$v.user_details.interviewTime.$dirty) return errors;
      !this.$v.user_details.interviewTime.required &&
        errors.push("Please select time.");
      return errors;
    },
    interviewCityErrors() {
      const errors = [];
      if (!this.$v.user_details.interviewCity.$dirty) return errors;
      !this.$v.user_details.interviewCity.required &&
        errors.push(this.$t("city_select_placeholder"));
      return errors;
    },
    selfieFileErrors() {
      const errors = [];
      if (!this.$v.user_details.selfieFile.$dirty) return errors;
      !this.$v.user_details.selfieFile.required &&
        errors.push(this.$t("file_select_placeholder"));
      return errors;
    },
    idTypeRules() {
      let obj = {};
      switch (this.id_type) {
        case "Passport":
        case "Drivers License":
          obj = {
            required,
            alphaNum,
          };
          break;
        default:
          obj = {
            required,
            alphaNum,
            numeric,
          };
          break;
      }
      return obj;
    },
  },
  validations() {
    return {
      id_type: {
        required,
      },

      user_details: {
        idNumber: this.idTypeRules,
        issue_location: {
          required: requiredIf(function () {
            if (this.id_type === "Passport") return true;
            else return false;
          }),
        },
        issue_date: {
          issueDate,
          required: requiredIf(function () {
            if (this.id_type === "Passport") return true;
            else return false;
          }),
        },
        expiry_date: {
          expireDate,
          required: requiredIf(function () {
            if (this.id_type === "Passport") return true;
            else return false;
          }),
        },
        first_page: {
          required,
        },
        selfieFile: {
          required,
        },
      },
    };
  },
  mounted() {
    this.getkycstatus();
  },

  methods: {
    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    openIdlast() {
      this.$refs.idlastfile.$el.querySelector("input").click();
    },
    openIdfirst() {
      this.$refs.idfirstfile.$el.querySelector("input").click();
    },

    async getkycdetails() {
      this.loader = true;

      try {
        const responseData = await userservice.getkycdetails();
        if (responseData.data.status_code === 200) {
          this.loader = false;
          const kycdetails = responseData.data.data[0];
          if (this.kycStatus) {
            this.success_msg = `Status: ${
              this.kycStatus === "Register"
                ? "Waiting for Approval"
                : this.kycStatus
            }`;
            this.kycStatus != "Draft" ? this.$emit("kycstatus") : null;
          }
          this.getidtypes();
          this.getCountryList();
          this.id_type_back = kycdetails.id_type_code
            ? kycdetails.id_type_code
            : null;
          this.user_details.idNumber = kycdetails.id_number
            ? kycdetails.id_number
            : "";
          this.user_details.issue_date = kycdetails.id_issue_date
            ? kycdetails.id_issue_date
            : null;

          this.user_details.expiry_date = kycdetails.id_expire_date
            ? kycdetails.id_expire_date
            : null;
          this.user_details.interviewDate = kycdetails.interview_date
            ? kycdetails.interview_date
            : null;
          this.user_details.interviewTime = kycdetails.interview_time
            ? kycdetails.interview_time
            : null;
          this.user_details.interviewCity = kycdetails.interview_city
            ? kycdetails.interview_city
            : null;
          this.user_details.issue_location = kycdetails.id_issue_place
            ? kycdetails.id_issue_place
            : null;
          this.user_details.last_page = kycdetails.back_page
            ? {
                name: kycdetails.back_page?.split("/").pop(),
                value: kycdetails.back_page,
              }
            : null;
          (this.backPageUrl = kycdetails.back_page
            ? kycdetails.back_page
            : null),
            (this.user_details.first_page = kycdetails.front_page
              ? {
                  name: kycdetails.front_page?.split("/").pop(),
                  value: kycdetails.front_page,
                }
              : null);
          (this.frontPageUrl = kycdetails.front_page
            ? kycdetails.front_page
            : null),
            (this.user_details.selfieFile = kycdetails.selfie
              ? {
                  name: kycdetails.selfie?.split("/").pop(),
                  value: kycdetails.selfie,
                }
              : null);

          (this.selfyPageUrl = kycdetails.selfie ? kycdetails.selfie : null),
            console.log("Kyc details", kycdetails);
        } else {
          this.loader = false;
        }
      } catch (e) {
        this.loader = false;
        return e;
      }
    },

    async getkycstatus() {
      this.loader = true;
      try {
        const responseData = await userservice.getkycstatus();
        if (responseData.data.success) {
          this.loader = false;
          this.kycStatus = responseData.data.data[0].kyc_status;
          this.getkycdetails();
        } else {
          this.loader = false;
        }
      } catch (e) {
        this.loader = false;
        return e;
      }
    },
    async getidtypes() {
      let response = await commonService.getParamterDropdown("DOC");
      response.data.data.map((idtype) => {
        this.idtypes.push(idtype.value);
        this.idtypes_val.push({
          name: idtype.value,
          id: idtype.value_code,
        });
        this.idtype_objs.push(idtype);
        if (idtype.value_code === this.id_type_back) {
          this.id_type = idtype.value;
        }
      });
    },
    async fileUpload(fileObject, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const imageurl = await URL.createObjectURL(fileObject);
      if (doc_type == "FRONT_PAGE") this.frontPageUrl = imageurl;
      if (doc_type == "BACK_PAGE") this.backPageUrl = imageurl;
      if (doc_type == "FACE_IMAGE") this.selfyPageUrl = imageurl;
      let fileData = new FormData();
      await fileData.append("document", compressdata);
      try {
        const responseData = await userservice.uploadKYCdocs(fileData, {
          doc_type,
        });
        if (responseData && responseData.data.success) {
          this.loader = false;
          this.showAlertSuccess(this.$t("file_uploaded_successfully"));
        } else {
          console.log("responseData", responseData);
          this.showAlertError(responseData.data.message);
          this.loader = false;
        }
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.showAlertError(
          "Error: something went wrong please try again sometime!"
        );
      }
    },
    ShowImage(imagebase64) {
      this.modelImage = imagebase64;
      this.$refs["my-modal"].show();
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext(save_draft) {
      if (this.$refs.passform.validate()) {
        this.$emit(
          "next",
          Object.assign(this.user_details, {
            id_proof_code: this.id_type_code,
            id_proof: this.id_type,
            save_draft: save_draft,
            // issue_location: this.user_details.issue_location,
            issue_location_code: this.issue_location_code,
          })
        );
        console.log("this.user_details", this.user_details);
        this.user_details;
      }
    },
    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },
    async getCountryList() {
      const responseData = await commonService.getNationalityList();
      this.country_options = responseData.data.map((i) => i.country_name);
      this.country_objs = responseData.data.map((i) => i);
      console.log("country_options", this.country_options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.demograpgics-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.date-birth {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.invalid-feedback {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.upload-div {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #d3d3d3 !important;
}
::v-deep .custom-control-input {
  &:checked ~ .custom-control-label {
    &::before {
      color: #fff;
      border-color: $green !important;
      background-color: $green !important;
    }
  }
}
::v-deep label {
  margin-bottom: 0 !important;
  color: $lightslategrey;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
svg.bi-cloud-upload-fill.b-icon.bi {
  position: absolute;
  top: 15px;
  left: 15px;
}
.mx-1.btn-group {
  height: 46px;
}
.file-select {
  top: 1px;
  height: 57px !important;
  z-index: 333;
  position: absolute;
  left: -1px;
  opacity: 0;
}
button.btn.mr-1.btn-warning {
  width: 51px;
  margin-right: 5px;
}
.selfie-image {
  margin-top: 10px;
  margin-right: 122px;
}
.date-birth.col {
  margin-top: 7px;
  margin-left: 30px;
}
.upload-image {
  height: 300px;
  width: 300px;
}
.file-label {
  padding: 10px;
}
</style>
