<template>
  <v-col cols="12">
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.9"
      color="#ff6b00"
      :height="height"
      :width="width"
    />
    <h5 class="text-center">
      {{ $t("kyc_failed") }}

      <v-btn @click="clearKycDetails()" class="primary--text pa-0" text>
        {{ $t("click_here_label") }}</v-btn
      >
      {{ $t("to_try_again") }}
    </h5>
  </v-col>
</template>

<script>
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
import Loading from "vue-loading-overlay";
export default {
  mixins: [alerts],
  data() {
    return {
      loader: false,
      fullPage: true,
      height: 100,
      width: 100,
    };
  },
  components: {
    Loading,
  },
  methods: {
    async clearKycDetails() {
      const user_id = sessionStorage.getItem("user_id");
      try {
        const responseData = await userservice.clearKycDetails({
          user_id: user_id,
        });
        console.log("responseData", responseData.data);
        if (responseData.data.status_code == 200) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
          this.$emit("kycupdate");
        } else {
          this.showAlertError(responseData.data.message);
          this.loader = false;
        }
      } catch (e) {
        this.showAlertError(
          "Error: something went wrong please try again sometime!"
        );
        this.loader = false;
        return e;
      }
    },
  },
};
</script>

<style></style>
