export function alpha(value) {
  return /^([a-zA-Z]+\s?)*$/.test(value) ? true : false;
}
export function address(value) {
  return /^([a-zA-Z0-9,.-/]+\s?)*$/.test(value) ? true : false;
}
export function addresslen(value) {
  return value.length < 98 ? true : false;
}
export function sharePer(value) {
  return value > 25 && value < 100 ? true : false;
}
export function aplhaNumSpace(value) {
  return /^[a-z\d\-\s]+$/i.test(value) ? true : false;
}
// export function aplhaNumSpecialchar(value) {
//   return /[w\[\]`!@#$%\^&*()={}:;<>+'-]*/g.test(value) ? true : false;
// }

export function issueDate(value) {
  return value < new Date().toJSON().split("T")[0] ? true : false;
}
export function expireDate(value) {
  return value > new Date().toJSON().split("T")[0] ? true : false;
}
export function checkLength(value) {
  return value.length === 11 ? true : false;
}
