<template>
  <v-col cols="12" md="12" class="d-flex justify-content-center mb-4">
    <div
      class="col-6 d-flex lighten-3 tab-text justify-center align-items-center grey w-25 text-center text-capitalize pa-3 primary--text font-weight-bold"
    >
      {{ $t("kyc_text") }}
    </div>
    <div
      class="grey lighten-3 col-6 tab-text w-50 text-center text-capitalize pa-3 dark--text font-weight-bold"
    >
      <span :class="`${returnColor(kycStatus)}--text`"> {{ message }}</span>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default() {
        return "";
      },
    },
    kycStatus: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    returnColor(val) {
      if (val === "Approved") {
        return "success";
      } else if (val === "Rejected") {
        return "error";
      } else {
        return "rimary";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
</style>
