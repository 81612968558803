<template>
  <v-dialog
    :value="showDialog"
    :height="picture.PicHeight"
    :width="picture.PicWidth"
    @hidden="closePictureDialog"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" elevation="2">
        {{ $t("preview")}}
        <v-spacer></v-spacer>
        <v-btn small fab icon color="black" @click.native="closePictureDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-img
              :src="picture.Pic"
              :height="picture.PicHeight"
              :width="picture.PicWidth"
              contain
              class="p-2"
            ></v-img>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "imageViewer",
  props: { picture: {}, showDialog: Boolean },
  methods: {
    closePictureDialog() {
      this.$emit("closeImageViewer");
    },
    mounted() {
      console.log(this.props);
    },
  },
};
</script>

<style></style>
