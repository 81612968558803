<template>
  <div class="mb-5">
    <h5 class="text-center">
      {{ $t("kyc_success_page") }}
    </h5>

    <div class="d-flex justify-space-around">
      <div>{{ $t("Notification") }}</div>
      <div>{{ $t("id_expire_priordate") }}</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

</style>
