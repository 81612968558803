<template>
  <div>
    <!-- kyc page for Evolet user -->
    <div class="page-body" v-if="this.$getLocalStorage('is_partner')">
      <TitleBar :title="$t('kyc_text')" :isKYCCompleted="btnDisable" />
      <v-container>
        <PassportSection
          :isloader="loader"
          :user_details="user_details"
          @next="onSubmitEkycDetails"
          :btnDisable="btnDisable"
          :disableVals="disableVals"
          @onClickBack="onClickBack"
          @kycstatus="kycstatus"
        />
      </v-container>
    </div>

    <!-- kyc page for customer portal user -->
    <div class="page-body" v-else>
      <TitleBar :title="$t('kyc_text')" :isKYCCompleted="btnDisable" />
      <v-container>
        <v-row class="ps-1 pe-1 d-flex justify-content-center ben_main">
          <v-col col sm="12" md="12" lg="12">
            <v-stepper
              v-model="currentStep"
              elevation="0"
              class="border"
              rounded="lg"
            >
              <v-col cols="6" class="m-auto">
                <v-stepper-header>
                  <v-stepper-step :complete="currentStep > 1" step="1">{{
                    $t("kyc_type")
                  }}</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="currentStep > 2" step="2">{{
                    $t("Kyc_text")
                  }}</v-stepper-step>
                </v-stepper-header>
              </v-col>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <kyc-type
                    @onClickNext="onClickNext"
                    @onClickBack="onClickBack"
                    :openKycDetials="openKycDetials"
                  />
                </v-stepper-content>
                <v-stepper-content step="2">
                  <PassportSection
                    :isloader="loader"
                    :user_details="user_details"
                    @next="onSubmitEkycDetails"
                    :btnDisable="btnDisable"
                    :disableVals="disableVals"
                    @onClickBack="onClickBack"
                    @kycstatus="kycstatus"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title
              >{{ $t("kyc_completed") }} &nbsp;<v-icon color="green"
                >mdi-check-circle-outline</v-icon
              ></v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <p>
                {{ $t("your") }} <b>{{ $t("kyc_text") }}</b>
                {{ $t("successfully_completed_on") }}
                {{ new Date().toLocaleDateString() }},
                {{ $t("kyc_notify_when_id_expiry") }}.
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn-primary-outlined text-capitalize"
                outlined
                @click="dialog = false"
              >
                {{ $t("okay") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>

<script>
import KycType from "@/components/KycPage/KycType.vue";
import TitleBar from "@/components/navigations/TitleBar.vue";
import PassportSection from "@/components/KycPage/PassportSection/index.vue";
// import EvoletKyc from "@/components/KycPage/PassportSection/EvoletKyc.vue";
import userservice from "@/services/UserService/userservice";
import croneservice from "@/services/CroneService/croneService";
import alerts from "@/mixins/alerts";
// import axios from "axios";
export default {
  name: "Transaction",
  mixins: [alerts],
  components: {
    TitleBar,
    PassportSection,
    KycType,
  },
  created() {
    const fname = sessionStorage.getItem("user_fname");
    const lname = sessionStorage.getItem("user_lname");
    this.user_details.fname = fname;
    this.user_details.lname = lname;
  },
  mounted() {
    this.$emit("getProfile");
  },
  data() {
    return {
      steps: [{ text: "kyc Type" }, { text: "kyc" }],
      disableVals: null,
      openKycDetials: true,
      is_partner: false,
      currentStep: 1,
      user_details: {
        fname: "",
        lname: "",
        fullname: "",
        fund: "",
        dob: null,
        nationality: "",
        gender: "",
        occupation: "",
        id_proof: "Passport",
        idNumber: "",
        employer: "",
        email: "",
        phone: "",
        incomeRange: "",
        firstAddressLine: "",
        secondAddressLine: "",
        interviewDate: null,
        interviewTime: "",
        interviewCity: "",
        country: {
          id: "",
          name: "",
          code: "",
        },
        state: {
          id: "",
          name: "",
          code: "",
        },
        city: {
          id: "",
          name: "",
        },
        dobValue: null,
        countryCod: "",
        fileUpload: "",
        issue_date: null,
        expiry_date: null,
        first_page: null,
        last_page: null,
        selfieFile: null,
        issue_location: "",
        issue_location_code: "",
      },
      kycdetails: {},
      submitError: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      successMessage: "",
      showDismissibleAlert: false,
      type: "Personal",
      facetoface: false,
      uploadSuccess: false,
      proof_options: [
        {
          text: "Passport",
          value: "Passport",
        },
      ],
      loader: false,
      btnDisable: false,
      dialog: false,
    };
  },
  methods: {
    onClickNext: function () {
      this.currentStep++;
    },
    kycstatus() {
      this.currentStep = 2;
      this.openKycDetials = false;
    },
    onClickBack: function () {
      this.currentStep--;
    },
    onClickDemographics() {
      if (this.user_details.dob) {
        const date = new Date(this.user_details.dob);
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        this.user_details.dobValue = [date.getFullYear(), mnth, day].join("-");
        this.currentStep++;
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    getDateFormat(valueDate) {
      const date = new Date(valueDate);
      const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formatDate = [date.getFullYear(), mnth, day].join("-");
      return formatDate;
    },
    async scanSanction() {
      try {
        const responseData = await croneservice.getscanSanction();
        return responseData;
      } catch (e) {
        return e;
      }
    },
    async onSubmitEkycDetails() {
      this.btnDisable = true;
      console.log(`user_details**: `, this.user_details);
      const issueDateValue = this.user_details.issue_date
        ? this.getDateFormat(this.user_details.issue_date)
        : null;
      const expiryDateValue = this.user_details.expiry_date
        ? this.getDateFormat(this.user_details.expiry_date)
        : null;

      try {
        this.loader = true;

        const data = {
          id_type_code: this.user_details.id_proof_code,
          id_number: this.user_details.idNumber,
          id_issue_date: issueDateValue ? issueDateValue : null,
          id_expire_date: expiryDateValue ? expiryDateValue : null,
          id_issue_place: this.user_details.issue_location,
          id_issue_country_code: this.user_details.issue_location_code,
          interview_date: this.user_details.interviewDate,
          interview_time: this.user_details.interviewTime,
          interview_city: this.user_details.interviewCity,
        };
        const responseData = await userservice.KYCdetalsFacetoFace(data);
        console.log("responseData: ", JSON.stringify(responseData));
        if (responseData && responseData.data.status_code == 200) {
          this.scanSanction();
          this.loader = false;
          this.showAlertSuccess(
            `${this.$t("kyc_submitted_wait_for_approval")}.`
          );
          this.btnDisable = true;
          this.disableVals = true;
        } else {
          console.log("responseData:", responseData);
          this.loader = false;

          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        console.log("error", error);
        this.loader = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}
.upload-div {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #d3d3d3 !important;
}
::v-deep .custom-control-input {
  &:checked ~ .custom-control-label {
    &::before {
      color: #fff;
      border-color: $green !important;
      background-color: $green !important;
    }
  }
}
::v-deep label {
  margin-bottom: 0 !important;
  color: $lightslategrey;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
svg.bi-cloud-upload-fill.b-icon.bi {
  position: absolute;
  top: 15px;
  left: 15px;
}
.mx-1.btn-group {
  height: 46px;
}
.file-select {
  top: 1px;
  height: 57px !important;
  z-index: 333;
  position: absolute;
  left: -1px;
  opacity: 0;
}
button.btn.mr-1.btn-warning {
  width: 51px;
  margin-right: 5px;
}
.selfie-image {
  margin-top: 10px;
  margin-right: 122px;
}
.date-birth.col {
  margin-top: 7px;
  margin-left: 30px;
}
.upload-image {
  height: 50px;
  width: 50px;
}
.file-label {
  padding: 10px;
}
</style>
